import React, { Component } from "react";
import styles from "./styles.scss";
import classnames from "classnames";
import Link from "next/link";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, WEB_ROOT } = publicRuntimeConfig;
import { injectIntl } from "react-intl";
import GridContainer from "../GridContainer";
import { withAppState } from "../../contexts/app-state";
import Column from './Column';
import Item from './Item';
import Social from './Social';
import ContactModal from './ContactModal/index'

class RondaFooter extends Component {
  state = {
    user: "",
    password: "",
    tabs: {},
    showMyTrips: false
  };
  contactModalMap = React.createRef();
  contactModalSchedule = React.createRef();
  componentDidMount() {
    this.setState({ tabs: this.getSelectedTab() });
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };
  isSelected = (path) => {
    if (typeof window !== "undefined") {
      return window.location.pathname.indexOf(path) == 0;
    }
    return false;
  };
  getSelectedTab = () => {
    let favorites = false;
    let mytrips = false;
    let planMyTrip = false;
    let calendar = false;
    let profile = false;
    let explorer = false;
    if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/profile`
      )
    ) {
      profile = true;
    } else if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/favorites`
      )
    ) {
      favorites = true;
    } else if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/my-trips`
      )
    ) {
      mytrips = true;
    } else if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/plan-my-trip`
      )
    ) {
      planMyTrip = true;
    } else if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/calendar`
      )
    ) {
      calendar = true;
    } else if (
      this.isSelected("/") ||
      this.isSelected(`/${this.props.intl.locale}`) ||
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}`
      )
    ) {
      explorer = true;
    }
    return {
      favorites,
      mytrips,
      planMyTrip,
      calendar,
      profile,
      explorer,
    };
  };
  render() {
    let search_query = `/${this.props.intl.locale}/${this.props.destination.rawId}/map`;
    let search_query_router = `/map?lang=${this.props.intl.locale}&destination=${this.props.destination.rawId}`;
    if (this.props.categoryRawId) {
      search_query = `${search_query}?c=${this.props.categoryRawId}`;
      search_query_router = `${search_query_router}&c=${this.props.categoryRawId}`;
      if (this.props.subcategoryRawId) {
        search_query = `${search_query}&subc=${this.props.subcategoryRawId}`;
        search_query_router = `${search_query_router}&subc=${this.props.subcategoryRawId}`;
      }
    }
    const { tabs, showMyTrips } = this.state;
    const { show_user_sidebar } = this.props;
    return (
      <div>
        <GridContainer className={this.props.desktopClassName}>
          <div className={styles.container}>
            <div className={classnames(styles.brand, styles['ronda'])} />
            <div className={styles.options}>
              <Column title={this.translate("footer.title.contact")}>
                <Item
                  label="informacion@turismoderonda.es"
                  href="mailto:informacion@turismoderonda.es"
                />
                <Item
                  label="Tlf: [+34] 952 18 71 19"
                  href="tel:+34952187119"
                />
                <Item label="Paseo Blas Infante (Ronda)" />
                <Social
                  facebook={this.props.destination.facebook}
                  instagram={this.props.destination.instagram}
                  linkedin={this.props.destination.linkedin}
                  youtube={'https://www.youtube.com/user/turismoderonda'}
                />
              </Column>
              <Column title={this.translate("footer.title.us")}>
                <Item
                  href={`/about?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/about`}
                  label={this.translate("footer.link.about.ronda")}
                />
                <Item
                  href={`/how-to-join?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/how-to-join`}
                  label={this.translate("footer.link.how-to-join")}
                />
                <Item
                  href={`/term?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/term`}
                  label={this.translate("footer.link.term")}
                />
                <Item
                  href={`/policy?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/policy`}
                  label={this.translate("footer.link.policy")}
                />
                <Item
                  href={`/legal/warning?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/legal/warning`}
                  label={this.translate("footer.link.legal.warning")}
                />
                <Item
                  href={`/cookie?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/cookie`}
                  label={this.translate("footer.link.cookie")}
                />
              </Column>
              <Column title={this.translate("footer.title.the.most.wanted")}>
                <Item
                  href={`/bono_turistico_ronda?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=que_ver&subcategoryRawId=arquitectura_plazas_monumentos`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/que_ver/arquitectura_plazas_monumentos/bono_turistico_ronda`}
                  label={this.translate("footer.title.ronda.bono")}
                />

                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=restaurantes`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/restaurantes`}
                  label={this.translate("categories.restaurants")}
                />

                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=alojamiento`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/alojamiento`}
                  label={this.translate("categories.accommodations")}
                />

                <Item
                  href={`/visitas_guiadas_a_ronda?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=que_ver,ocio,experiencias_actividades&subcategoryRawId=cool_places,eventos_,experiencias_y_actividades_`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/que_ver,ocio,experiencias_actividades/cool_places,eventos_,experiencias_y_actividades_/visitas_guiadas_a_ronda`}
                  label={this.translate("footer.title.ronda.visitas")}
                />

                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=que_ver`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/que_ver`}
                  label={this.translate("categories.to_see")}
                />

                <Item
                  href={`/bodegas_vinos_ronda?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=experiencias_actividades`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/experiencias_actividades/bodegas_vinos_ronda`}
                  label={this.translate("footer.title.ronda.bodegas")}
                />

              </Column>
              <Column title={this.translate("footer.title.information")}>
                <Item
                  onClick={() => {
                    if (this.contactModalMap.current) this.contactModalMap.current.show();
                  }}
                  label={this.translate("footer.link.map")}
                />
                <div className={styles.column}>
                  <a className={styles.item} target="_blank" href="https://info.turismoderonda.es/uncategorized/informacion-descargable/">
                  {this.translate("footer.link.schedule")}
                  </a>
                </div>
                <div className={styles.column}>
                  <a className={styles.item} target="_blank" href="http://info.turismoderonda.es/es/informacion-basica/catalogo-de-servicios/turismo/guias-oficiales-de-turismo?limitstart=0">
                  {this.translate("footer.link.official.guides")}
                  </a>
                </div>
                <span className={styles.title}>{this.translate("footer.link.job")}</span>
                <Item
                  href={`/jobs?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/jobs`}
                  label={"Anuncio Convocatorias"}
                />
                <span className={styles.title}>{this.translate("footer.title.plan")}</span>
                <Item
                  href={`/plan-my-trip?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/plan-my-trip`}
                  label={this.translate("footer.link.plan-my-trip")}
                />
                <Item
                  href={`/calendar?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/calendar`}
                  label={this.translate("footer.link.calendar")}
                />
              </Column>
            </div>
          </div>
        </GridContainer>
        <nav
          className={classnames(
            styles.mobileContainer,
            this.props.mobileClassName
          )}
        >
           <>
            {
              showMyTrips &&
              <Link
                href={`/my-trips?lang=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                as={`/${this.props.intl.locale}/${this.props.destination.rawId}/my-trips`}
              >
                <a
                  className={
                    tabs.mytrips && !show_user_sidebar
                      ? styles.selectedicon
                      : undefined
                  }
                >
                  <span className={classnames(styles.icon, styles[APP], styles.rotate)}></span>
                  <span className={styles.title}>
                    {this.translate("footer.link.my-trips")}
                  </span>
                </a>
              </Link>
            }
          </>

          <>
            {
              !showMyTrips &&
              <Link
                href={`/plan-my-trip?lang=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                as={`/${this.props.intl.locale}/${this.props.destination.rawId}/plan-my-trip`}
              >
                <a
                  className={
                    tabs.planMyTrip && !show_user_sidebar
                      ? styles.selectedicon
                      : undefined
                  }
                >
                  <span className={classnames(styles.icon, styles[APP], styles.rotate)}></span>
                  <span className={styles.title}>
                    {this.translate("footer.link.plan-my-trip")}
                  </span>
                </a>
              </Link>
            }
          </>

          <Link
            href={`/calendar?lang=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
            as={`/${this.props.intl.locale}/${this.props.destination.rawId}/calendar`}
          >
            <a
              className={
                tabs.calendar && !show_user_sidebar
                  ? styles.selectedicon
                  : undefined
              }
            >
              <span
                className={classnames(styles.icon, styles.iconCalendar)}
              ></span>
              <span className={styles.title}>
                {this.translate("footer.link.calendar")}
              </span>
            </a>
          </Link>

          <Link
            href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&`}
            as={`/${this.props.intl.locale}/${this.props.destination.rawId}?aopen=true`}
          >
            <a
              className={
                tabs.explorer && !show_user_sidebar
                  ? styles.selectedicon
                  : undefined
              }
            >
              <span className={classnames(styles.icon, styles.iconHome)}></span>
              <span className={styles.title}>
                {this.translate("footer.link.explore")}
              </span>
            </a>
          </Link>

          <Link
            href={`/favorites?lang=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
            as={`/${this.props.intl.locale}/${this.props.destination.rawId}/favorites`}
          >
            <a
              className={
                tabs.favorites && !show_user_sidebar
                  ? styles.selectedicon
                  : undefined
              }
            >
              <span
                className={classnames(styles.icon, styles.iconFavorites)}
              ></span>
              <span className={styles.title}>
                {this.translate("footer.link.favorites")}
              </span>
            </a>
          </Link>


          <a
            className={
              show_user_sidebar ? styles.selectedicon : undefined
            }
            href={`/${this.props.intl.locale}/${this.props.destination.rawId}/profile`}
            onClick={(e) => {
              e.preventDefault();
              this.props.changeLoginPopup();
            }}
          >
            <span
              className={classnames(styles.icon, styles.iconProfile)}
            ></span>
            <span className={styles.title}>
              {this.translate("footer.link.profile")}
            </span>
          </a>

          <a
            className={
              show_user_sidebar ? styles.selectedicon : undefined
            }
            href={`/${this.props.intl.locale}/${this.props.destination.rawId}/jobs`}
          >
            <span
              className={classnames(styles.icon, styles.iconJob)}
            ></span>
            <span className={styles.title}>
              {this.translate("footer.link.job")}
            </span>
          </a>
        </nav>

        <ContactModal
          destination={this.props.destination}
          type={'Map'}
          ref={this.contactModalMap}
        />

        <ContactModal
          destination={this.props.destination}
          type={'Schedule'}
          ref={this.contactModalSchedule}
        />

      </div>
    );
  }
}

RondaFooter.defaultProps = {
  city: {},
  user: {},
};

export default injectIntl(withAppState(RondaFooter));
